
.storyview-section{
    display:flex;
    justify-content: center;
    align-items: top;
    gap:2em;
    padding:8vh 0 0 0
}


.storyview-story{
    background-color: white;
    margin-bottom: 2em;
    padding:1em 2em;
    border-radius:1em;
    width: 40vw;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}

.storyview-profile{
    background-color: white;
    padding:1em 2em;
    border-radius:1em;
    width: 18vw;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.comment-section{
    background-color: white;
    padding:1em 2em;
    border-radius:1em;
    height:100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    
}

.recommended-container{
    margin: 2em 0;
    flex:1;
}

.recommended-container h2{
    font-size: 1.2em;
    padding:1em 0 ;
}

.profile-container{
    display:flex;
    flex-direction: column;
}




.comment-body{
    margin:  1em 0 ;
    padding: 1em;
    border-radius:1em;
    background: #f8f8f8a2;
}
.comment-body h4{
    margin:0;
}

.author-name{
    color: #FF8882;
    
}


.child-carousel{
    display:grid;
    width: 90vw;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 2em;
    padding:4em;
}

.child-carousel > * {
    flex: 0 0 auto; 

}

.children-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.story-link{
    display: grid;
    justify-content: center;
    align-content: center;
}

.storyview-body{
    background-color: #f8f8f8a2;
    padding: 1em 1em;
    border-radius: 1em;
    line-height: 1.8em;
}

@media screen and (max-width: 1200px){
    .storyview-section{
        padding:16vh 0 0 0;
    }
    .storyview-story{
        width:50vw;
    }
    .storyview-profile{
        width:30vw;
    }

    .children-form{
        margin:4em 0;
    }

  
  }

  

@media screen and (max-width: 600px){
    .storyview-section{
        flex-direction:column-reverse;
        align-items: center;
        padding:16vh 0 0 0;
    }
    .storyview-story{
        width:75vw;
    }
    .storyview-profile{
        width:70vw;
    }

    .children-form{
        margin:4em 0;
    }

    .child-carousel{
        grid-template-columns: repeat(1, 1fr);
        padding: 0;
    }
  
    .child-carousel .story-card{
        margin:0;
    }
  
  }