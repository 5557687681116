.stories-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2em;
}

.search-container{
    display: flex;
    justify-content: center;
    padding: 0 0 4em;
}



@media screen and (max-width: 600px){
    .stories-container{

    }
  
  
  }
