@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/** 
background : antiquewhite
             rgb(43, 45, 59)


**/

html {
  scroll-behavior: smooth;
}


.primary{
  color:#FF8882;
}

.secondary{
  color:#189AB4 ;
}

.error{
  color:#DE4E49; 
}

body {
  background-color: antiquewhite;
  margin:0;
  padding:0;
  font-family: 'Montserrat', sans-serif;
}

input[type=text], input[type=email], input[type=password], textarea.tags{
  padding: 8px 8px;
  border-radius:10px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1em;
  height:37px;
}


textarea {
  resize: none;
}
input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, textarea:focus{
  outline:none;
  border: 1px solid #FF8882!important;
}

label{
  padding:0.4em 0 ;
}

hr.handsome{
  border-top: 2px solid rgba(232, 131, 107, 0.2);
  width:30vw;
}

.chevron-link a{
  color:#a6a6a6;
  font-size:1.6em;

}
.chevron-link{
  padding:1em;
  transition: 0.2s ease 0s;
}

.about-scrirrel:hover .chevron-link{
  animation: bounceChev 0.6s infinite alternate;

}

@keyframes bounceChev {
  to {
    opacity: 0.9;
    transform: translate3d(0, -4px, 0);
  }
}


.button{
  background-color: #FF8882;
  color: white;
  font-weight: bold;
  border-radius:10px;
  padding: 8px 16px;
  border:none;
  margin:0;
}
.button:hover{
  cursor: pointer;
}

.button.secondary{
  background-color: #189AB4;
}

.highlighted{
  color: #189AB4;
}

.avatar{
  width:6em;
  height:6em;
  object-fit: cover;
  border-radius:3em;
  box-shadow:  rgba(0, 0, 0, 0.8) 0px 8px 16px -8px;
}

.footer{
  background-color: white;
  text-align: center;
  padding: 2vh 0 2vh 0;
}

.donate-links{
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  gap:1em;
}

.donate-links > a{
  text-decoration: none;
  color:#189AB4;
  font-weight: bold;
  padding-bottom:1em;
}

.copy{
  font-weight: bold;
  padding: 5vh 0 0 0;
}

/** Loader **/

.loader {
  display: flex;
  justify-content: center;
  margin-top: 45vmin;
}

.loader > div {
  background: #189AB4;
  width: 16px;
  height: 16px;
  opacity: 0.6;
  margin: 0.3rem;
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  to {
    opacity: 0.5;
    transform: translate3d(0, -20px, 0);
  }
}

/** End of Loader **/

.row-section{
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin:4em 12vw;
  gap:2em;
}

.container{
  background-color: white;
  height: min-content;
  width:33vw;
  padding:1em 2em;
  border-radius:1em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.about-card{
  text-align: center;
}

.form{
  display: flex;
  flex-direction: column;
}

.form > *{
  width: 100%;
}

.banner{
  background-color: #189AB4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 20vh;
  background-image:  linear-gradient(rgba(24, 154, 180, 0.9), rgba(24, 154, 180, 0.9)), url("./img/banner01.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.footer-banner{
  background-color: #FF8882;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 20vh;
  background-image:  linear-gradient(rgba(255, 136, 130, 0.8), rgba(255, 136, 130, 0.8)), url("./img/banner03.jpg");
  background-repeat: no-repeat;
  background-position: 40% 37%;
}

.icon:hover{
  color:#189AB4;
}


.modal {
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(14, 84, 97, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-body{
  min-height: 20vh;
  min-width: 30vw;
  text-align: center;
  background-color: white;
}
.pagination-container{
  padding: 4em 0 0 0 ;
  margin:0;
}


.pagination{
  display: flex;
  justify-content: center;

}
.pagination-item {
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-radius: 16px;
  font-size: 1.4em;
  min-width: 32px;
  cursor:pointer;
}
.pagination-item.selected{
  color:#189AB4;
}

.story-card-small{
  display:flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 1em;
  background-color: white;
  padding:0 1em ;
  border-radius:1em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

/* Animations */

.anim{
  border-right: 4px solid black;
  white-space: nowrap;
  overflow: hidden;
}

.anim-type{
  animation: typewriter 4s steps(40) 2s 1 normal both,
             blinkCursor 500ms steps(40) infinite normal;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blinkCursor{
  from {
    border-right-color: white;
  }
  to {
    border-right-color: transparent;
  }
}


/* Media Queries */

@media screen and (max-width: 600px){
  .container{
    width:75vw;
    
  }

  .row-section{
    margin: 4em 0;
    padding:0;
    align-items: center;
    flex-direction: column;
  }


}