.nav{
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: space-around;


}

.nav-link{
    text-decoration: none;
    color: #26252a;
}

.nav-items{
    width:50vw;
    display:flex;
    align-items: center;
    justify-content: flex-end;
}

.nav-items > * {
    margin: 12px;
}

.ham{
  display:none
}

.logo{
  height:5em;
  margin: 6px 0 ;
  

}



@media screen and (max-width: 600px){
    .ham{
      display:block;
    }
    .nav{
      height:fit-content;
      flex-direction: column;
    }
    .nav-items{
      flex-direction: column;
      transition: 3s ;
      opacity: 1;
      height:50vh;
      transition: 0.5s ;
      transition-delay: 200ms;
      transition-property: opacity, height;
    }

    .nav-items.closed{
      opacity: 0;
      height:0;
    }

    .nav-group{
      width:100vw;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }


  
