.story-card{
    background-color: white;
    padding:1em 2em;
    border-radius:1em;
    width: 18vw;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;


}

.story-card:hover{
    transform: translateY(-20px);
    transition: 1s;
}

.story-body{
    background-color: #f8f8f8a2;
    padding: 1em 1em;
    border-radius: 1em;
    margin-bottom: 1em;

}
.get-clamped p:first-child{
    line-height: 1.8em;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.get-clamped p:not(:first-child){
    display: none;

}

.tags{
    font-weight: bold;
}




@media screen and (max-width: 600px){
    .story-card{
        width: 65vw;
    }

}
